import React from 'react';
import PropType from 'prop-types';
import longArrow from '../images/long_arrow.svg';
import './cclButton.scss';

const CCLButton = props => {
  let btnStyle;
  let disabled = props.disabled ? props.disabled : false;
  let arrow = props.arrow ? props.arrow : false;

  switch (props.style) {
    case 'primary':
      btnStyle = 'ccl-primary';
      break;
    case 'outline':
      btnStyle = 'ccl-outline';
      break;
    case 'ghost':
      btnStyle = 'ccl-ghost';
      break;
    default:
      break;
  }

  let onClickFn = props.onClick;

  if(props.link) {
    onClickFn = () => {
      window.open(props.link);
    };
  }

  return (
    props.tagName == 'a' ? <a href={props.link} target="_blank" className={'ccl-btn ' + btnStyle} type={props.type} disabled={disabled}>
        {props.label}
        {arrow && <img src={longArrow} alt=" "/>}
    </a> : <button className={'ccl-btn ' + btnStyle} type={props.type} disabled={disabled} onClick={onClickFn}>
        {props.label}
        {arrow && <img src={longArrow} alt=" "/>}
    </button>
  );
};

CCLButton.propType = {
  label: PropType.string.isRequired,
  style: PropType.string.isRequired
};

export default CCLButton;